import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  fill?: Color;
  title?: string;
  size?: keyof typeof unitMapping;
} & React.JSX.IntrinsicElements['svg'];
export const Neutral = ({
  fill = 'var(--c-white)',
  title,
  size = 3,
  ...rest
}: Props) => {
  return (
    <svg
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{ height: unitMapping[size] }}
      {...rest}
    >
      <title>{title}</title>
      <path d="m4 8v4h14v-4z" fill={fill} />
    </svg>
  );
};
