import { differenceInDays, differenceInMilliseconds } from 'date-fns';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';

export const PriceHistoryDate = ({ date }: { date: Date }) => {
  const now = Date.now();
  if (differenceInDays(now, date) < 7)
    return (
      <FormattedRelativeTime
        value={differenceInMilliseconds(date, now) / 1000}
        updateIntervalInSeconds={60}
      />
    );
  return (
    <FormattedDate value={date} day="2-digit" month="short" year="numeric" />
  );
};
