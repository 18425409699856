import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { HeadlineM } from 'atoms/typography';
import { Dialog } from 'components/dialog';
import { useScreenSize } from 'hooks/device/useScreenSize';
import { glossary } from 'lib/glossary';
import { TextDocument } from 'routing/TextDocument/lazy';

const Footer = styled.div`
  padding: var(--triple-unit);
`;
const Content = styled.div`
  padding: var(--triple-unit);
  margin: 0 calc(-1 * var(--unit));
`;

type Terms =
  | 'terms'
  | 'voucherTerms'
  | 'referralTerms'
  | 'derbiVascoCampaignReferralTerms'
  | 'italianSuperCupReferralTerms'
  | 'zidaneCampaignTerms'
  | 'loanTerms';

const messages = defineMessages<Terms>({
  terms: {
    id: 'ConversionCredit.TermsDialog.path',
    defaultMessage: 'DISCOUNT_CREDIT_TERMS.md',
  },
  voucherTerms: {
    id: 'CustomLeague.ConversionCredit.TermsDialog.path',
    defaultMessage: 'DISCOUNT_VOUCHER_CREDIT_TERMS.md',
  },
  referralTerms: {
    id: 'ConversionCredit.TermsDialog.referralTerms.path',
    defaultMessage: 'DISCOUNT_REFERRAL_CREDIT_TERMS.md',
  },
  derbiVascoCampaignReferralTerms: {
    id: 'ConversionCredit.TermsDialog.derbiVascoCampaignReferralTerms.path',
    defaultMessage: 'DERBI_VASCO_REFFERAL_CAMPAIGN_TERMS.md',
  },
  italianSuperCupReferralTerms: {
    id: 'ConversionCredit.TermsDialog.italianSuperCupReferralTerms.path',
    defaultMessage: 'ITALIAN_SUPERCUP_REFERRAL_CAMPAIGN_TERMS.md',
  },
  zidaneCampaignTerms: {
    id: 'ConversionCredit.TermsDialog.zidaneCampaignTerms.path',
    defaultMessage: 'ZZ_OIALS2_REFERRAL_CAMPAIGN_TERMS.md',
  },
  loanTerms: {
    id: 'ConversionCredit.TermsDialog.loanTerms.path',
    defaultMessage: 'LOAN_OFFER_TERMS.md',
  },
});

type Props = {
  terms?: Terms;
  fileName?: string;
  onClose: () => void;
};
export const TermsDialog = ({ terms = 'terms', fileName, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const { up: isLaptop } = useScreenSize('laptop');
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open
      onClose={onClose}
      fullScreen={!isLaptop}
      title={
        <HeadlineM className="text-center" as="h3">
          <FormattedMessage
            id="TermsDialog.title"
            defaultMessage="Terms & conditions"
          />
        </HeadlineM>
      }
      footer={
        <Footer>
          <Button fullWidth size="medium" color="secondary" onClick={onClose}>
            <FormattedMessage {...glossary.ok} />
          </Button>
        </Footer>
      }
    >
      <Content dir="ltr">
        <TextDocument document={fileName || formatMessage(messages[terms])} />
      </Content>
    </Dialog>
  );
};
