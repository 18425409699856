import algoliasearchHelper, {
  PlainSearchParameters,
} from 'algoliasearch-helper';
import { SearchResponse } from 'algoliasearch-helper/types/algoliasearch';
import { SearchClient } from 'algoliasearch/lite';
import { useEffect, useState } from 'react';

import { useSearchClient } from '@sorare/core/src/hooks/search/useSearchClient';

interface Props {
  index?: string;
  attribute: string;
  values?: string[];
  params?: PlainSearchParameters;
  skip?: boolean;
}

const EMPTY: Record<string, number> = {};

const facetCounts = async (
  searchClient: SearchClient,
  index: string,
  props: Omit<Props, 'skip' | 'index'>
) => {
  const { attribute, values, params } = props;

  const baseParams = algoliasearchHelper(searchClient, index, {
    ...params,
  }).getQuery();

  const filteredORedAttribute = [...new Set(values)].map(
    v => `${attribute}:${v}`
  );

  return searchClient
    .search([
      {
        indexName: index,
        type: 'default',
        params: {
          ...baseParams,
          analytics: false,
          facets: [attribute],
          distinct: false,
          hitsPerPage: 0,
          page: 0,
          maxValuesPerFacet:
            values?.length || baseParams.maxValuesPerFacet || 10,
          facetFilters: [
            filteredORedAttribute,
            ...((baseParams.facetFilters || []) as string[]),
          ],
        },
      },
    ])
    .then(
      ({ results }) =>
        (results[0] as SearchResponse<unknown>).facets?.[attribute] || {}
    );
};

export const useFacetCounts = (props: Props) => {
  const { index, skip, attribute, params, values } = props;
  const [counts, setCounts] = useState<Record<string, number>>(EMPTY);
  const searchClient = useSearchClient();

  useEffect(() => {
    if (!index || skip) {
      return;
    }

    facetCounts(searchClient, index, { params, attribute, values }).then(
      res => {
        setCounts(res);
      }
    );
  }, [attribute, index, params, searchClient, skip, values]);

  if (!index || skip) {
    return EMPTY;
  }

  return counts;
};
