import { Children, PropsWithChildren, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { CSSProperties } from 'styled-components';

import { LoadingButton } from 'atoms/buttons/LoadingButton';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Dialog } from 'components/dialog';
import { Header } from 'components/dialog/Header';
import { Stepper } from 'components/onboarding/Dialog/Stepper';
import { glossary } from 'lib/glossary';
import { tabletAndAbove } from 'style/mediaQuery';

const StyledDialog = styled(Dialog)`
  @media ${tabletAndAbove} {
    height: min(100vh - 8 * var(--unit), 812px);
  }
`;
const Root = styled(Vertical).attrs({ gap: 0 })`
  justify-content: space-between;
  height: 100%;
  position: relative;
  isolation: isolate;
`;
const SlidingContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--element-count), 1fr);
  width: calc(var(--element-count) * 100%);
  transition: transform 0.3s ease;
  height: 100%;
  overflow: auto;
`;
const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;
const StepperWrapper = styled.div`
  width: 112px;
`;
const FooterButtons = styled(Horizontal).attrs({ gap: 2 })`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 var(--double-unit) var(--double-unit);

  & > * {
    flex: 1;
  }
`;

type Props = {
  open: boolean;
  onSkip?: () => void;
  onNext?: () => void;
  onDone?: () => void;
  onClose: () => void;
  lastCtaLabel?: ReactNode;
  loadingSkip?: boolean;
  loadingNext?: boolean;
};
export const OnboardingStepDialog = ({
  open,
  onSkip,
  onNext,
  onDone,
  onClose,
  children,
  lastCtaLabel,
  loadingSkip,
  loadingNext,
}: PropsWithChildren<Props>) => {
  const [step, setStep] = useState(0);
  const childrenLength = Children.toArray(children).length;
  const isFirstStep = step === 0;
  const isLastStep = step === childrenLength - 1;

  const onClickNext = () => {
    setStep(step + 1);
    onNext?.();
  };

  return (
    <StyledDialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      hideHeader
    >
      <Root>
        <HeaderWrapper>
          <Header
            title={
              <Horizontal center>
                <StepperWrapper>
                  <Stepper
                    length={childrenLength}
                    step={step}
                    setStep={setStep}
                    backgroundColor="rgba(var(--c-rgb-white), 0.11)"
                  />
                </StepperWrapper>
              </Horizontal>
            }
            onClose={onClose}
            transparentButton
          />
        </HeaderWrapper>
        <SlidingContentWrapper
          style={
            {
              '--element-count': childrenLength,
              transform: `translateX(calc(-1/${childrenLength} * ${step} * 100%)`,
            } as CSSProperties
          }
        >
          {children}
        </SlidingContentWrapper>
        <FooterButtons>
          <LoadingButton
            color="tertiary"
            size="medium"
            onClick={isFirstStep ? onSkip || onClose : () => setStep(step - 1)}
            disableDebounce
            loading={!!loadingSkip}
          >
            {isFirstStep ? (
              <FormattedMessage {...glossary.skip} />
            ) : (
              <FormattedMessage {...glossary.back} />
            )}
          </LoadingButton>
          <LoadingButton
            color={isLastStep ? 'primary' : 'secondary'}
            size="medium"
            onClick={isLastStep ? onDone || onClose : onClickNext}
            disableDebounce
            loading={!!loadingNext}
          >
            {isLastStep ? (
              <>{lastCtaLabel || <FormattedMessage {...glossary.letsGo} />}</>
            ) : (
              <FormattedMessage {...glossary.next} />
            )}
          </LoadingButton>
        </FooterButtons>
      </Root>
    </StyledDialog>
  );
};
