import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { glossary, transferMarket } from '@sorare/core/src/lib/glossary';
import { isType } from '@sorare/core/src/lib/gql';

import { TransactionLabel_tokenDeal } from './__generated__/index.graphql';

export const TransactionLabel = ({
  deal,
}: {
  deal: TransactionLabel_tokenDeal;
}) => {
  if (isType(deal, 'TokenAuction'))
    return <FormattedMessage {...glossary.auction} />;
  if (isType(deal, 'TokenPrimaryOffer'))
    return <FormattedMessage {...transferMarket.instantBuy} />;
  return <FormattedMessage {...transferMarket.publicOffer} />;
};

TransactionLabel.fragments = {
  tokenDeal: gql`
    fragment TransactionLabel_tokenDeal on TokenDeal {
      ... on TokenAuction {
        id
      }
      ... on TokenOffer {
        id
      }
      ... on TokenPrimaryOffer {
        id
      }
    }
  ` as TypedDocumentNode<TransactionLabel_tokenDeal>,
};
