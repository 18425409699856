import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';

import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { FlexCard } from 'components/token/FlexCard';

import {
  PriceHistoryQuery,
  PriceHistoryQueryVariables,
  useGetPriceHistory_anyCard,
} from './__generated__/useGetPriceHistory.graphql';

const cardFragment = gql`
  fragment useGetPriceHistory_anyCard on AnyCardInterface {
    slug
    collection
    sport
    pictureUrl
    rarityTyped
    anyPlayer {
      slug
      displayName
    }
    user {
      slug
    }
    ...FlexCard_anyCard
  }
  ${FlexCard.fragments.anyCard}
` as TypedDocumentNode<useGetPriceHistory_anyCard>;

const PRICE_HISTORY_QUERY = gql`
  query PriceHistoryQuery(
    $playerSlug: String!
    $rarity: Rarity!
    $seasonEligibility: SeasonEligibility
  ) {
    tokens {
      tokenPrices(
        playerSlug: $playerSlug
        rarity: $rarity
        seasonEligibility: $seasonEligibility
      ) {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        date
        deal {
          ... on TokenAuction {
            id
            cards: anyCards {
              slug
              ...useGetPriceHistory_anyCard
            }
          }
          ... on TokenOffer {
            id
            senderSide {
              id
              cards: anyCards {
                slug
                ...useGetPriceHistory_anyCard
              }
            }
          }
          ... on TokenPrimaryOffer {
            id
            cards: anyCards {
              slug
              ...useGetPriceHistory_anyCard
            }
          }
        }
      }
    }
  }
  ${monetaryAmountFragment}
  ${cardFragment}
` as TypedDocumentNode<PriceHistoryQuery, PriceHistoryQueryVariables>;

export const useGetPriceHistory = (args: PriceHistoryQueryVariables) => {
  const prepareAcceptOffer = useQuery(PRICE_HISTORY_QUERY, {
    variables: args,
  });
  return prepareAcceptOffer;
};

export const useLazyGetPriceHistory = () => {
  return useLazyQuery(PRICE_HISTORY_QUERY);
};
