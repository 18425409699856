import { addBreadcrumb } from '@sentry/react';
import { differenceInDays, intervalToDuration, isAfter } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { useTickerContext } from 'contexts/ticker';
import { sendSafeError } from 'lib/error';
import {
  TabNum,
  TimeOptions,
  defaultTimeMessages,
  formatDurationMessage,
  split,
} from 'lib/time';

const timeLeftMessage = (
  now: Date,
  time: Date,
  { totalDays, customMessages }: TimeOptions = {}
) => {
  let duration;
  try {
    duration = intervalToDuration({ start: now, end: time });
  } catch {
    addBreadcrumb({
      level: 'error',
      message: `Start date: ${now}\nEnd date: ${time}`,
    });
    sendSafeError(new Error('timeLeftMessage: invalid dates provided'));
    return {
      elements: null,
      message: null,
    };
  }
  const elements = split(duration);
  const message = formatDurationMessage(
    duration,
    elements,
    customMessages || defaultTimeMessages,
    totalDays
  );

  return {
    elements,
    message: (
      <FormattedMessage
        {...message}
        values={{
          ...elements,
          ...(totalDays
            ? { days: differenceInDays(time, now), months: undefined }
            : {}),
        }}
      >
        {txt => <TabNum>{txt}</TabNum>}
      </FormattedMessage>
    ),
  };
};

export const useTimeLeft = (time: Nullable<Date>, options?: TimeOptions) => {
  const { now } = useTickerContext();

  if (!time) {
    return {
      elements: null,
      isEnded: false,
      message: null,
    };
  }

  const isEnded = isAfter(now, time);
  const { elements, message } = timeLeftMessage(now, time, options);

  return {
    elements,
    isEnded,
    message,
  };
};
